@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;

.about {
  &__container {
    display: flex;
    padding-bottom: 9rem;
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    max-width: 64rem;
    @include tablet {
      padding-bottom: 9rem;
    }
    @include desktop {
      padding-bottom: 12rem;
    }
  }
  &__section {
    display: flex;
    max-width: 64rem;
    flex-direction: column;
    align-items: center;
    margin: 0 2rem;
    padding: 2rem 2rem;
    box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%),
      0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%),
      0 16px 32px rgb(0 0 0 / 7%), 0 32px 64px rgb(0 0 0 / 7%);
    background: linear-gradient(
      90deg,
      #3f51b5 0%,
      hsl(219deg 46% 49% / 43%) 100%
    );
    @include tablet {
      align-items: unset;
      flex-direction: row;
      margin: 0 4rem;
      // height: 19.3rem;
      padding: 1.7rem 2rem;
    }
    @include desktop {
      padding: 3rem 3rem;
      width: 100%;
      margin: 0 auto;
      height: 27rem;
    }
  }
  &__addition {
    display: none;
    position: relative;
    top: -3.5rem;
    height: 15%;
    border-top: 1px solid white;
    z-index: 4;
    max-width: 64rem;
    margin: 0 auto;
    padding: 0 29rem;
    @include tablet {
      display: flex;
      width: 74.8%;
      padding: 0;
      top: -1.7rem;
    }
    @include desktop {
      top: -3.5rem;
      height: 15%;
      padding: 0 29rem;
      width: auto;
    }
  }
  &__shapes {
    display: flex;
  }
  &__content {
    width: 100%;
    border-radius: 0.125rem;
  }
  &__image {
    border-left: 2px solid #61f4de;
    border-top: 2px solid #d397fa;

    object-fit: contain;
    position: relative;
    display: flex;
    justify-content: center;

    padding: 1rem;
    width: 100%;
    border-radius: 0.125rem;
    @include tablet {
      padding: 0.8rem;
      width: 12rem;
      max-height: 10rem;
    }
    @include desktop {
      padding: 1rem 1rem;
      width: 16rem;
      max-height: 14rem;
    }
  }
  &__title {
    color: white;
    margin: -0.5rem 0 0 0;
    font-size: 9vw;
    width: 100%;
    text-align: end;
    padding-bottom: 0.1rem;
    @include tablet {
      position: relative;
      font-size: 3.3rem;
      top: 1.1rem;
      text-align: left;
      left: -2.4rem;
      width: 73%;
    }
    @include desktop {
      text-align: end;
      font-size: 5rem;
      top: 2.4rem;
      left: -4rem;
      width: 57%;
    }
  }
  &__text {
    margin: 0.3rem 0px 0px 0.3rem;
    font-size: 3.8vw;
    z-index: 1;
    color: white;
    @include tablet {
      position: relative; 
      text-align: justify;
      margin: 1.3rem 0px 1.6rem 0.3rem;
      // margin: 1.3rem 0px 0px 0.3rem;
      font-size: 0.79rem;
      word-spacing: -2px;
    }
    @include desktop {
      margin: 2.9rem 0 0 0.5rem;
      font-size: 1rem;
    }
  }
}
