html {
    box-sizing: border-box;
  }
  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Helvetica',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(315deg, hsla(209, 54%, 72%, 1) 18%, hsla(242, 61%, 58%, 1) 76%);
  }

  
a {
  text-decoration: none;
  color: #FFF;
}