@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;

.nav {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: hidden;
  &__directory {
    display: none;
    @include tablet {
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: flex-end;
      list-style-type: none;
      margin: 0;
    }
  }
  &__link {
    min-height: 40px;
    padding: 1.5rem 0;
    margin-left: 2rem;
    font-size: 1rem;
    font-weight: 400;
    color: white;
    transform: translateZ(0);
    cursor: pointer;
    @include tablet {
      padding: 2rem 0;
      margin-left: 0.9rem;
      font-size: 0.8rem;
    }
    @include desktop {
      padding: 1.5rem 0;
      margin-left: 2rem;
      font-size: 1rem;
    }
  }
  &__link::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width 0.3s;
  }

  &__link:hover::after {
    width: 100%;
  }
  &__section {
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    z-index: 10;
    margin-right: 2rem;
    @include tablet {
      margin-right: 4rem;
    }
    @include desktop {
      margin-right: 2rem;
    }
  }
  &__logo {
    width: 4rem;
    display: none;
  }
  &__icons {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    width: 50%;
    @include tablet {
      margin-left: 4rem;
    }
    @include desktop {
      margin-left: 2rem;
    }
  }
  &__icon {
    padding: 1rem 0;
    margin-right: 1rem;
    transition-duration: 1.5s;
    cursor: pointer;
    @include tablet {
      margin-right: 1.1rem;
    }
    @include desktop {
      margin-right: 2rem;
    }
  }
  &__icon:hover {
    transform: rotateY(360deg);
  }
  &__portal {
    width: 0.95rem;
    @include tablet {
      width: 1rem;
    }
    @include desktop {
      width: 1.5rem;
    }
  }
}
