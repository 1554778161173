@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;

.skills {
  max-width: 64rem;
  display: flex;
  flex-direction: column;
  margin: 0 2rem;
  padding-bottom: 9rem;
  @include tablet {
    margin: 0 auto;
  }
  &__text {
    @include tablet {
      margin: 0 4rem;
    }
    @include desktop {
      margin: 0;
    }
  }
  &__title {
    font-size: 2.5rem;
    margin: 0 0 0.4rem 0;
    color: white;
    @include tablet {
      font-size: 3.3rem;
    }
    @include desktop {
      font-size: 3.5rem;
      margin: 0 0 1rem 0;
    }
  }
  &__info {
    color: white;
    margin: 0 0 2rem 0;
    font-size: 0.8rem;
    @include tablet {
      margin: 0 0 0rem 0;
    }
    @include desktop {
      font-size: 1rem;
      margin: 0 0 2rem 0;
    }
  }
  &__item-title {
    margin: 0;
    color: white;
    padding-bottom: 0.3rem;
    font-size: 0.8rem;
    @include tablet {
      font-size: 0.8rem;
      padding-bottom: 0.6rem;
      font-size: 0.9rem;
    }
    @include desktop {
      font-size: 1rem;
    }
  }
  &__item {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    transition: 0.25s;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.25rem;
    background: #3f51b5;
    justify-content: center;
    padding: 0.5rem;
    height: 7rem;
    @include desktop {
      padding: 2rem;
      height: 11rem;
    }
  }

  &__item:hover {
    transform: translateY(-0.5rem);
  }
  &__image {
    width: 3.15rem;
    @include tablet {
      width: 3.55rem;
    }
    @include desktop {
      width: 6rem;
    }
  }
  &__container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 2rem;
    @include tablet {
      // display: flex;
      // justify-content: space-between;
      gap: 1.4rem;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      padding-top: 2rem;
      margin: 0 4rem;
    }
    @include desktop {
      gap: 2.5rem;
      margin: 0;
    }
  }
}
