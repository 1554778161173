@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;

.contact {
  margin: 0 2rem;
  max-width: 64rem;
  padding-bottom: 12rem;
  @include tablet {
    margin: 0 4rem;
  }
  @include desktop {
    padding-bottom: 12rem;
    margin: 0 auto;
  }
  &__heading {
    width: 100%;
    z-index: 2;
    font-size: 2.5rem;
    margin: 0;
    color: white;
    font-weight: 800;
    @include tablet {
      font-size: 3.3rem;
    }
    @include desktop {
      font-size: 4.8rem;
    }
  }
  &__form-heading {
    font-size: 1.4rem;
    margin: 0.2rem 0 1.75rem 0;
    color: white;
    @include tablet {
      font-size: 1.7rem;
      margin: 0rem 0 1.05rem 0;
    }
    @include desktop {
      font-size: 2rem;
      margin: 0.2rem 0 1.75rem 0;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      gap: 4rem;
    }
    @include desktop {
      // justify-content: unset;
      gap: 5rem;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    background: #3f51b5;
    border-radius: 0.75rem;
    color: white;
    padding: 2rem;
    @include tablet {
      width: 50%;
      margin: 0;
      padding: 2rem 2rem;
    }
    @include desktop {
      width: 43%;
      padding: 3rem;
    }
  }
  &__lower {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__label {
    // padding-bottom: 0.75rem;
    padding: 0.3rem;
    font-size: 12px;
    @include tablet {
      font-size: 12px;
      padding: 0.1rem 0.3rem;
    }
    @include desktop {
      padding: 0.3rem;
      font-size: 14px;
    }
  }
  &__input {
    margin-bottom: 0.5rem;
    padding: 0.3rem;
    border: none;
    border-bottom: 1px solid white;
    background: none;
    color: white;
    @include tablet {
      margin-bottom: 0.7rem;
    }
    @include desktop {
      margin-bottom: 0.5rem;
    }
    &--extended {
      margin-bottom: 0.5rem;
      border: none;
      border-bottom: 1px solid white;
      background: none;
      outline: none;
      color: white;
      @include tablet {
        margin-bottom: 0.7rem;
      }
      @include desktop {
        margin-bottom: 0.5rem;
      }
    }
  }
  &__button {
    width: 38%;
    height: 2rem;
    font-size: 0.7rem;
    padding: 0.45rem 0.45rem;
    margin-top: 1.25rem;
    background: white;
    color: #3f51b5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border: none;
    font-weight: 800;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    -webkit-transition: all 0.25s ease-in-out;
    cursor: pointer;
    @include tablet {
      width: 50%;
      margin-top: 1.15rem;
      padding: 0.55rem 0.45rem;
    }
    @include desktop {
      padding: 0.85rem 0.45rem;
      margin-top: 2.25rem;
    }
  }
  &__button:hover {
    transform: translateY(-0.125rem);
  }
  &__highlight {
    color: #d397fa;
  }
  &__image {
    width: 22rem;
    position: relative;
    margin: -3.7rem 0 -3rem 4rem;
    white-space: nowrap;
    object-fit: cover;
    display: none;
    @include tablet {
      display: flex;
      margin: -2.7rem 0 -3rem 4rem;
      width: 13rem;
    }
    @include desktop {
      width: 22rem;
      margin: -3.7rem 0 -3rem 4rem;
    }
  }
  &__image-2 {
    width: 7rem;
    margin: -4rem 0 -4.3rem 1.5rem;
    object-fit: contain;
    @include tablet {
      width: 9.25rem;
      margin: -6.25rem 0 -4.2rem 3.7rem;
    }
    @include desktop {
      width: 56%;
      margin: -9rem 0 -7.2rem 6.4rem;
    }
  }
}

.info {
  &__heading {
    color: #546be6;
    margin: 0 0 0.3rem 0;
    font-size: 0.9rem;
    text-transform: uppercase;
    width: 100%;
    @include tablet {
      // padding: 0 2rem;
      width: 50%;
      margin: 6.4rem 0 0.3rem 0;
      font-size: 1.15rem;
    }
    @include desktop {
      font-size: 1.35rem;
      margin: 7.1rem 0 0.3rem 0;
    }
  }
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #ebebeb5d;
}

textarea::placeholder {
  color: #ebebeb5d;
}
