@use "./variables" as *;

$font-stack: "Helvetica";

html {
  font-size: $root-font-size;
}
@font-face {
  font-family: $font-stack;
  src: url("../assets/fonts/HelveticaNeueRegular.woff2") format("woff2"),
    url("../assets/fonts/HelveticaNeueRegular.woff") format("woff"),
    url("../assets/fonts/HelveticaNeue\ Regular.ttf") format("truetype"),
    url("../assets/fonts/HelveticaNeueRegular.svg") format("svg");
  font-weight: 300;
  font-style: light;
}
@font-face {
  font-family: $font-stack;
  src: url("../assets/fonts/HelveticaNeueLight.woff2") format("woff2"),
    url("../assets/fonts/HelveticaNeueLight.woff") format("woff"),
    url("../assets/fonts/HelveticaNeue\ Light.ttf") format("truetype"),
    url("../assets/fonts/HelveticaNeueLight.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: $font-stack;
  src: url("../assets/fonts/HelveticaNeueMedium.woff2") format("woff2"),
    url("../assets/fonts/HelveticaNeueMedium.woff") format("woff"),
    url("../assets/fonts/HelveticaNeue\ Medium.ttf") format("truetype"),
    url("../assets/fonts/HelveticaNeueMedium.svg") format("svg");
  font-weight: 600;
  font-style: bold;
}
@font-face {
  font-family: $font-stack;
  src: url("../assets/fonts/HelveticaNeueBold.woff2") format("woff2"),
    url("../assets/fonts/HelveticaNeueBold.woff") format("woff"),
    url("../assets/fonts/Helvetica\ Neu\ Bold.ttf") format("truetype"),
    url("../assets/fonts/HelveticaNeueBold.svg") format("svg");
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: $font-stack;
  src: url("../assets/fonts/HelveticaNeueBold.woff2") format("woff2"),
    url("../assets/fonts/HelveticaNeueBold.woff") format("woff"),
    url("../assets/fonts/Helvetica\ Neu\ Bold.ttf") format("truetype"),
    url("../assets/fonts/HelveticaNeueBold.svg") format("svg");
  font-weight: 900;
  font-style: bold;
}

body {
  font-family: $font-stack;
}
