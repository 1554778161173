@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;

.landing {
  height: 100vh;
  width: 100%;
  &__title {
    max-width: 100%;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    -webkit-transition: all 0.5s ease-in-out;
    color: white;
    border-bottom: 3px solid white;
    @include tablet {
      max-width: 80%;
      font-size: 5rem;
    }
    @include desktop {
      max-width: 73%;
      font-size: 7rem;
    }
  }
  &__heading {
    display: flex;
    flex-direction: column;
    max-width: 64rem;
    margin: 0 2rem;
    height: 100%;
    justify-content: center;
    @include tablet {
      margin: 0 4rem;
    }
    @include desktop {
      margin: 0 auto;
    }
  }
  &__sub-title {
    font-size: 1.2rem;
    margin: 0.75rem 0 0 0;
    font-weight: 700;
    color: midnightblue;
    @include tablet {
      font-size: 1.85rem;
      margin: 0.25rem 0 0 0;
    }
    @include desktop {
      font-size: 2.75rem;
      margin: 0.25rem 0 0 0;
    }
  }
  &__bio {
    font-weight: 400;
    margin-top: 0.75rem;
    font-size: 0.8rem;
    width: 100%;
    margin-bottom: 1.65rem;
    color: white;
    @include tablet {
      font-size: 0.925rem;
      margin-bottom: 2.05rem;
    }
    @include desktop {
      font-size: 1.125rem;
      margin-top: 0.75rem;
    }
  }
  &__button {
    width: 7.1rem;
    font-size: 0.725rem;
    padding: 0.15rem 0.15rem 0.05rem 0.8rem;
    border: 2.5px solid white;
    border-radius: 1.5rem;
    font-weight: 400;
    color: white;
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-transition: all 0.25s ease-in-out;
    cursor: pointer;
    @include tablet {
      width: 8rem;
      font-size: 0.825rem;
      padding: 0.25rem 0.35rem 0.25rem 1rem;
    }
    @include desktop {
      width: 11rem;
      font-size: 1.125rem;
      padding: 0.65rem 0.35rem 0.65rem 1rem;
    }
  }
  &__button:hover {
    transition: 0.5s;
    background: white;
    color: #5551d5;
  }
}
