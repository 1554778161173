@use "../../styles/typography" as *;
@use "../../styles/mixins" as *;

.projects {
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 2rem;
  max-width: 64rem;
  @include tablet {
    margin: 0 4rem;
    padding-bottom: 7rem
  }
  @include desktop {
    margin: 0 auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    font-size: 2.5rem;
    margin: 0 0 4rem 0;
    color: white;
    @include tablet {
      font-size: 3.3rem;
      margin: 0 0 3rem 0;
    }
    @include desktop {
      font-size: 3.5rem;
      margin: 0 0 4rem 0;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 7rem;
  transform: 0.2s;
  @include tablet {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;
    transform: 0.2s;
  }
  &__left {
    width: 100%;
    flex: 1;
    border-radius: 0.75rem;
    @include tablet {
      width: 50%;
    }
  }
  &__right {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    background: #3f51b5;
    border-radius: 0.75rem;
    flex: 1;
    z-index: 1;

    padding: 1.8rem 1.1rem;

    width: 90%;
    margin-top: -150px;
    @include tablet {
      margin-top: 0;
      margin-left: -15rem;
      width: 50%;
      padding: 1.8rem 1.7rem;
    }
    @include desktop {
      padding: 2.5rem 1.7rem;
    }
  }
  &__wrapper {
    width: 100%;
    border-radius: 0.75rem;
    box-shadow: 0px 0px 20px #4705fc33;
    overflow: hidden;
    position: relative;
    transition: 1s;
    @include tablet {
      height: 26rem;
    }
    @include desktop {
      height: 34rem;
    }
  }
  &__image {
    width: 100%;
    height: 21rem;
    transition: all 0.5s ease-in-out;
    @include tablet {
      height: auto;
    }
  }
  &__wrapper:hover::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.75rem;
  }
  &__title {
    color: white;
    margin: 0 0 1rem 0;
    font-size: 1.4rem;
    border-left: 3px solid #61f4de;
    padding: 0.3rem 0.6rem;
    border-top: 2px solid #d397fa;
    width: 10rem;
    @include tablet {
      margin: 0 0 0.9rem 0;
      font-size: 1.85rem;
      padding: 0.3rem 0.7rem;
      width: 8.8rem;
    }
    @include desktop {
      margin: 0 0 1.5rem 0;
      font-size: 2.25rem;
      padding: 0.5rem 0.9rem;
      width: 10rem;
    }
  }
  &__text {
    color: white;
    margin: 0;
    font-size: 0.8rem;
    @include tablet {
      font-size: 0.9rem;
    }
    @include desktop {
      font-size: 1rem;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    @include tablet {
    flex-direction: row;
      gap: 3rem;
      margin: 1.1rem 0 0 0;
    }
    @include desktop {
      gap: 6rem;
      margin: 2rem 0 0 0;
    }
  }
  &__button {
    padding: 0.35rem 0.8rem;
    font-size: 1rem;
    width: 100%;
    border: none;
    background: white;
    color: #3f51b5;
    border-radius: 1.5rem;
    font-weight: 400;
    align-items: center;
    -webkit-transition: all 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    cursor: pointer;
    @include tablet {
      width: 5rem;
      padding: 0.4rem 0rem;
      font-size: 0.9rem;
    }
    @include desktop {
      width: 8rem;
      padding: 0.55rem 1rem;
      font-size: 1.15rem;
    }
    &--alt {
      padding: 0.35rem 0.8rem;
      font-size: 1rem;
      width: 100%;
      border-radius: 1.5rem;
      color: white;
      background: none;
      border: 2px solid white;

      -webkit-transition: all 0.25s ease-in-out;
      cursor: pointer;
      @include tablet {
        width: 5rem;
        padding: 0.4rem 0rem;
        font-size: 0.9rem;
      border: 2.5px solid white;
      }
      @include desktop {
        width: 8rem;
        padding: 0.55rem 1rem;
        font-size: 1.15rem;
      }
    }
    &--alt:hover {
      transform: translateY(-0.2rem);
    }
  }
  &__button:hover {
    transform: translateY(-0.2rem);
  }
}

.item:hover .item__image {
  transform: scale(1.1);
  z-index: 1;
  opacity: 0.4;
  transition: 0.6s;
}

.item:nth-child(2n) {
  @include tablet {
    flex-direction: row-reverse;
  }
}

.item:nth-child(2n) .item__right {
  @include tablet {
    z-index: 1;
    margin-right: -15rem;
    margin-left: 0;
  }
}
